import { iconForum, iconLocked, iconMaximize, iconMinimize } from '@wfp/icons';
import { iconRestartGlyph } from '@wfp/icons';
import { Icon, Tag } from '@wfp/ui';
import { formatDistanceToNow } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Element } from 'react-scroll';

import ConfirmationModal from 'src/components/ConfirmationModal';
import { CRF23 } from 'src/constants';
import { useIsMount } from 'src/hooks';
import {
  commentReplyRequestAPI,
  commentRequestAPI,
  commentRestoreRequestAPI,
} from 'src/redux/actions';
import {
  COMMENTS_LIST,
  REPLY,
  REPLY_DETAIL,
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';
import {
  anchorsArrayFromNarrative,
  getDateWithTimezone,
  getUserInfo,
  sendGAEvent,
} from 'src/utils';
import CommentControlPanel from './CommentControlPanel';
import CommentsTextInput from './commentsTextInput';
import ReviewIcon from './ReviewIcon';

export const CommentListItemComponent = ({
  data,
  commentsContainerID,
  sectionAPIKwargs,
  props,
  id,
  nested = false,
  parentCommentID,
  issuesOverview = false,
  activeUser,
  permissions,
  parentClosed,
  highlightIsHovered,
}) => {
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(data?.closed || false);
  const [replyCommentContent, setReplyCommentContent] = useState('');
  const [newContent, setNewContent] = useState(data.content || '');
  const [highlightAnchor, setHighlightAnchor] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showCloseConfirmationModal, setCloseConfirmationModal] =
    useState(false);
  const [showRestoreConfirmationModal, setRestoreConfirmationModal] =
    useState(false);
  const [edit, setEdit] = useState(false);
  const [replyComment, setReplyComment] = useState(false);
  const [newTitle, setNewTitle] = useState(data.title || '');

  const appConfig = useSelector(
    state => state.viewData?.GET_APP_CONFIGURATION?.data,
  );
  const roles = appConfig?.userInfo?.roles;
  const isHqAdmin = roles && roles.includes('hq_admin');

  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      updateAnchorStyle();
    }
  }, [highlightAnchor]);

  function toggleEdit() {
    setEdit(!edit);
  }

  function toggleReplyComment() {
    setReplyComment(!replyComment);
  }

  function toggleDeleteConfirmationModal() {
    setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
  }

  function toggleCloseConfirmationModal() {
    setCloseConfirmationModal(!showCloseConfirmationModal);
  }

  function toggleRestoreConfirmationModal() {
    setRestoreConfirmationModal(!showRestoreConfirmationModal);
  }

  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }

  function updateAnchorStyle() {
    const elements = document.querySelectorAll(`[id='${data.anchor}']`);
    elements.forEach(element => {
      if (!element.classList.contains('comment-anchor')) return;

      if (highlightAnchor) {
        element.style.backgroundColor = '#e03e04';
      } else {
        element.style.backgroundColor = 'transparent';
      }
    });
  }

  function updateComment(requestData, method, refreshNarrative = false) {
    let refreshNarrativeType = '';

    if (refreshNarrative) {
      const narrativeArray = anchorsArrayFromNarrative(props.data.content);
      const isLinkedMainNarrative = narrativeArray.includes(data.anchor);

      if (isLinkedMainNarrative) {
        refreshNarrativeType = 'main';
      } else {
        let isLinkedExtraNarrative = false;

        props.data.extra_narratives.forEach(extraNarrative => {
          const extraNarrativeArray = anchorsArrayFromNarrative(
            extraNarrative.content,
          );

          if (extraNarrativeArray.includes(data.anchor))
            isLinkedExtraNarrative = true;
        });

        if (isLinkedExtraNarrative) refreshNarrativeType = 'extra';
      }

      if (props.is2023CRF) refreshNarrativeType = 'multi';
    }

    const params = {
      url_format: {
        ...sectionAPIKwargs,
        _comment_: data.pk,
      },
      method,
      data: requestData,
      storage_key: COMMENTS_LIST,
      reportId: props.reportId,
      refreshNarrative: refreshNarrativeType,
    };

    dispatch(commentRequestAPI(params));
  }

  function createReply() {
    toggleReplyComment();
    setReplyCommentContent('');

    const params = {
      url_format: {
        ...sectionAPIKwargs,
        _comment_: data.pk,
      },
      method: 'post',
      data: { content: replyCommentContent },
      storage_key: REPLY,
    };

    dispatch(commentReplyRequestAPI(params));
  }

  function updateExistingComment() {
    const requestData = { content: newContent };
    if (!nested) {
      requestData['title'] = newTitle;
      requestData['edit'] = true;
      updateComment(requestData, 'patch');
      toggleEdit();
    } else {
      toggleEdit();
      const params = {
        url_format: {
          ...sectionAPIKwargs,
          _comment_: parentCommentID,
          _reply_: data.pk,
        },
        method: 'patch',
        data: requestData,
        storage_key: REPLY_DETAIL,
      };
      dispatch(commentReplyRequestAPI(params));
    }
  }

  function deleteCommentReply() {
    toggleDeleteConfirmationModal();

    const params = {
      url_format: {
        ...sectionAPIKwargs,
        _comment_: parentCommentID,
        _reply_: data.pk,
      },
      method: 'delete',
      data: {},
      storage_key: REPLY_DETAIL,
    };

    dispatch(commentReplyRequestAPI(params));
  }

  function deleteComment() {
    setHighlightAnchor(false);
    updateComment({}, 'delete', true);
  }

  function markAsClosed() {
    setHighlightAnchor(false);
    updateComment({ closed: true }, 'patch', true);
    toggleCloseConfirmationModal();
  }

  function restoreComment() {
    dispatch(
      commentRestoreRequestAPI({
        url_format: {
          ...sectionAPIKwargs,
          _comment_: parentCommentID,
          _reply_: data.pk,
        },
        data: {
          comment_id: id,
        },
      }),
    );
    toggleRestoreConfirmationModal();
  }

  function toggleAsImportant() {
    updateComment({ important: !data?.important }, 'patch');
  }

  function updateReplyContent(e) {
    setReplyCommentContent(e.target.value);
  }

  function updateNewContent(e) {
    setNewContent(e.target.value);
  }

  function updateNewTitle(e) {
    setNewTitle(e.target.value);
  }

  function getDeleteCommentHeading() {
    const closingText = 'Do you really want to proceed?';
    const headingComment = `You are about to delete comment with ID ${data.pk}${
      data.comments?.length > 0 ? ' and all its replies.' : '.'
    } \n${closingText}`;
    const headingIssue = `You are about to delete reply with ID ${data.pk}. \n${closingText}`;

    return nested ? headingIssue : headingComment;
  }

  const nested_comments = data?.comments?.map((comment_data, index) => {
    const id = comment_data.pk;

    const props = {
      data: comment_data,
      key: index,
      id,
      parentClosed: data?.closed,
      parentCommentID: data?.pk,
      activeUser: activeUser,
      sectionAPIKwargs: !issuesOverview ? sectionAPIKwargs : null,
      commentsContainerID: !issuesOverview ? commentsContainerID : null,
      permissions: !issuesOverview ? permissions : {},
      nested: true,
      issuesOverview,
    };
    return <CommentListItem key={id} {...props} />;
  });

  const deleteMethod = !nested ? deleteComment : deleteCommentReply;

  return (
    <Element
      className={`${
        nested
          ? 'nested-comment'
          : data?.closed
          ? 'closed-comment'
          : data?.important
          ? 'important-comment'
          : ''
      }
          ${highlightIsHovered ? 'highlighted' : ''}
          acr-comment`}
      onMouseEnter={() => setHighlightAnchor(true)}
      onMouseLeave={() => setHighlightAnchor(false)}
      name={data.anchor}
      id={data.anchor}
      data-test-id="comment-item"
    >
      {!nested && (
        <div className="comment-cover">
          <div className="acr-comment-header">
            <Icon
              width="30"
              height="30"
              fill="grey"
              description="Thread"
              icon={data?.closed ? iconLocked : iconForum}
              className="acr-comment-title acr-comment-title-icon"
            />
            <div className="acr-comment-title" data-test-id="comment-title">
              <div className="acr-comment-title-text">{data.title}</div>
              {collapsed && (
                <div className="acr-comment-title__pk-part">
                  Comment ID: {data.pk}
                </div>
              )}
            </div>
          </div>
          {data?.closed && (
            <Icon
              width="20"
              height="20"
              fill="grey"
              description="Thread"
              onClick={() => setCollapsed(!collapsed)}
              icon={collapsed ? iconMaximize : iconMinimize}
              className="acr-comment-title acr-comment-title-icon"
            />
          )}

          {!issuesOverview && !isEmpty(permissions) && (
            <ReviewIcon
              permissions={permissions}
              collapsed={collapsed}
              closed={data?.closed}
              user={data?.user}
              activeUser={activeUser}
              data={data}
              toggleCollapsed={toggleCollapsed}
              toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
              isReportPublished={props.reportIsPublished}
            />
          )}
        </div>
      )}

      <div className={collapsed ? 'collapsed-comment' : `comment-body`}>
        {data?.restored && (
          <Tag
            className="thread-restored-label"
            type="custom"
            data-test-id="restored-label"
          >
            Restored
          </Tag>
        )}
        {data?.closed ? (
          <Tag
            className="thread-closed-label"
            type="custom"
            data-test-id="closed-thread"
          >
            Closed
          </Tag>
        ) : data?.important ? (
          <Tag className="" type="error" data-test-id="high-importance">
            High Importance
          </Tag>
        ) : (
          ''
        )}
        <div className="comment-info">
          <div className="acr-comment-username" data-test-id="user-name">
            {getUserInfo(data.user)}
          </div>
          <div className="comment-date" data-test-id="comment-date">
            {getDateWithTimezone(data.date)}
            <br />
            {`${formatDistanceToNow(data.date.date)} ago`}
          </div>
        </div>
        <div className="comment-body__pk-part" data-test-id="comment-id">
          {nested ? 'Reply' : 'Comment'} ID: {data.pk}
        </div>

        {!issuesOverview && (
          <>
            {showDeleteConfirmationModal && (
              <ConfirmationModal
                open={true}
                onSubmit={() => {
                  sendGAEvent('comment_delete_confirmation_btn_clicked');
                  deleteMethod();
                }}
                collapsed-comment
                onClose={toggleDeleteConfirmationModal}
                heading={getDeleteCommentHeading()}
                primaryButtonText="Yes"
                secondaryButtonText="No"
                customClassName="delete-comment-modal"
              />
            )}

            {showCloseConfirmationModal && (
              <ConfirmationModal
                open={true}
                onSubmit={markAsClosed}
                onClose={toggleCloseConfirmationModal}
                heading={`Do you really want to mark comment with ID ${data.pk} as closed?`}
                primaryButtonText="Yes"
                secondaryButtonText="No"
                customClassName="close-comment-modal"
              />
            )}

            {showRestoreConfirmationModal && (
              <ConfirmationModal
                open={true}
                onSubmit={restoreComment}
                onClose={toggleRestoreConfirmationModal}
                heading={`Do you really want to restore comment with ID ${data.pk}?`}
                primaryButtonText="Yes"
                secondaryButtonText="No"
                customClassName="restore-comment-modal"
              />
            )}
          </>
        )}

        <div className="comment-content" data-test-id="comment-content">
          {data.content}
        </div>
        {!issuesOverview && !props.reportIsPublished && (
          <div className="acr-comment-control-panel">
            {isHqAdmin && data?.closed && (
              <span
                className="acr-comment-icon-holder"
                onClick={() => {
                  if (!props.isCommentsUpdating) {
                    toggleRestoreConfirmationModal();
                    return;
                  }
                  return null;
                }}
                data-test-id="reply-link"
              >
                <Icon
                  description="Reply"
                  icon={iconRestartGlyph}
                  height="16"
                  id={id}
                  className="noselect issue-reply-icon"
                />
                <span
                  className="acr-comment-icon-label"
                  data-test-id="restore-link"
                >
                  Restore
                </span>
              </span>
            )}
            {!data?.closed && (
              <CommentControlPanel
                permissions={permissions}
                nested={nested}
                closed={data?.closed}
                user={data?.user}
                activeUser={activeUser}
                id={id}
                important={data?.important}
                parentClosed={parentClosed}
                toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
                toggleCloseConfirmationModal={toggleCloseConfirmationModal}
                toggleEdit={toggleEdit}
                toggleReplyComment={toggleReplyComment}
                markAsImportant={toggleAsImportant}
                isCommentsUpdating={props.isCommentsUpdating}
              />
            )}
          </div>
        )}
        {replyComment && !issuesOverview && (
          <CommentsTextInput
            displayTitleInput={false}
            contentValue={replyCommentContent}
            handleChange={updateReplyContent}
            submitButtonLabel="Post Reply"
            handleSubmit={() => {
              sendGAEvent('comment_post_reply_btn_clicked');
              createReply();
            }}
          />
        )}

        {edit && !issuesOverview && (
          <CommentsTextInput
            displayTitleInput={!nested}
            contentValue={newContent}
            titleValue={newTitle}
            handleChange={updateNewContent}
            handleChangeTitle={updateNewTitle}
            submitButtonLabel={`Update ${nested ? 'Reply' : 'Comment'}`}
            handleSubmit={updateExistingComment}
          />
        )}
        {nested_comments}
      </div>
    </Element>
  );
};

export const mapStateToProps = state => {
  return {
    props: {
      activeSectionID: 0,
      isCommentsUpdating: state.viewData[COMMENTS_LIST]?.isFetching || false,
      ...state.viewData[SECTION_DETAIL_DATA_KEY],
      ...state.viewState,
      is2023CRF: state.viewData[REPORT_DETAIL_DATA_KEY]?.data?.crf === CRF23,
      reportId: state.viewData[REPORT_DETAIL_DATA_KEY]?.data?.pk,
      reportIsPublished:
        state.viewData[REPORT_DETAIL_DATA_KEY]?.data?.state === 'Published',
    },
  };
};

const CommentListItem = connect(mapStateToProps)(CommentListItemComponent);
export default CommentListItem;
